import styled from "@emotion/styled";

import { Flex } from "@src/components/Boxes";
import Container from "@src/components/Container";
import SEO from "@src/components/SEO";
import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import { getRelativePath } from "@src/utils/routes";
import { Text } from "@src/components/Text";
import GatsbyImageWrapper from "@src/components/GatsbyImageWrapper";

const Header = styled("h1")`
  font-size: 48px;
  font-weight: 900;
  line-height: 52px;
  margin: 22px 0 11px;
`;

const Strong = styled("strong")`
  font-weight: 700;
`;

const TextLink = styled(Link)`
  color: #00bcf2;
  font-weight: 600;

  :hover {
    color: #00a8d9;
  }
`;

const Image = styled(GatsbyImageWrapper)`
  width: 100%;
  max-width: 375px;
  margin: 0 auto;
`;

function SummerKindnessSeriesJoined() {
  const data = useStaticQuery(graphql`
    {
      image: file(name: { eq: "section1-img" }, relativePath: { regex: "/summer-kindness-series/" }) {
        childImageSharp {
          gatsbyImageData(width: 375, quality: 90, placeholder: NONE, layout: CONSTRAINED)
        }
      }
    }
  `);
  const { image } = data;
  return (
    <>
      <SEO
        title="The Summer Kindness Series"
        description="A simple, weekly series for families to build new habits at home and inspire compassionate kids!"
        image="https://static.classdojo.com/img/summer-landing/homepage-feature-image.jpg"
        twitter={{
          card: "summary_large_image",
          site: "@classdojo",
          creator: "@classdojo",
          title: "ClassDojo's Summer Kindness Series",
          description:
            "A simple, weekly series for families to build new habits at home and inspire compassionate kids!",
          image: "https://static.classdojo.com/img/summer-landing/homepage-feature-image.jpg",
        }}
      />
      <Container maxWidth="970px" paddingTop="66px" paddingBottom={[0, 0, "66px"]}>
        <Flex flexWrap="wrap">
          <Flex width={["100%", "100%", "50%"]} px="15px" flexDirection="column" marginBottom="50px">
            <Header>That's a wrap!</Header>
            <Text marginTop="30px" fontSize="18px">
              <Strong> The Summer Kindness Series is over, but you can still get the good stuff ;) </Strong>
              <br />
              <br />
              Go back to the Summer Kindness Series homepage{" "}
              <TextLink to={getRelativePath("/summer-kindness-series/")}>here</TextLink> to access each of the 9
              kindness habits and activities!
            </Text>
          </Flex>
          <Flex width={["100%", "100%", "50%"]} px="15px">
            <Image image={image} alt="ClassDojo characters planting a tree" />
          </Flex>
        </Flex>
      </Container>
    </>
  );
}

export default SummerKindnessSeriesJoined;
